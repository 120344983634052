// Theme
import {
  BsInputCursorText,
  BsStarHalf,
  BsCalendarDate,
  BsListOl,
  BsTextarea,
} from "react-icons/bs";
import { ImSortNumbericDesc } from "react-icons/im";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { VscListSelection } from "react-icons/vsc";

export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  BLUE: "BLUE",
  INDIGO: "INDIGO",
  YELLOW: "YELLOW",
  CYAN: "CYAN",
  PINK: "PINK",
  TEAL: "TEAL",
  ORANGE: "ORANGE",
  BLUE_GRAY: "BLUE_GRAY",
  PURPLE: "PURPLE",
  RED: "RED",
};
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const CHANGE_SELFIE_USER = "CHANGE_SELFIE_USER";
export const CHANGE_EMPRESA = "CHANGE_EMPRESA";
export const CHANGE_PREFERENCES_DASHBOARD = "CHANGE_PREFERENCES_DASHBOARD";
export const CHANGE_LAST_ACCESS = "CHANGE_LAST_ACCESS";
export const CHANGE_MFA = "CHANGE_MFA";
export const CHANGE_NOTIFICACOES = "CHANGE_NOTIFICACOES";

export const DOMINIO_TIPO_ARQUIVO_PESSOA = Object.freeze({
  BONUS: { cod: 2, desc: "Bônus", name: "BONUS" },
  CERTIFICADOS: { cod: 8, desc: "Certificados", name: "CERTIFICADOS" },
  COMISSAO: { cod: 3, desc: "Comissão", name: "COMISSAO" },
  COMPROVANTE_AJUSTE_ABONO: {
    cod: 12,
    desc: "Comprovante de Ajuste/Abono de Ponto",
    name: "COMPROVANTE_AJUSTE_ABONO",
  },
  DOCUMENTOS: { cod: 9, desc: "Documentos", name: "DOCUMENTOS" },
  ENTREGA_EPI: { cod: 17, desc: "Entrega de EPI", name: "ENTREGA_EPI" },
  ENTREGA_UNIFORME: {
    cod: 16,
    desc: "Entrega de Uniforme",
    name: "ENTREGA_UNIFORME",
  },
  ESPELHO_PONTO: { cod: 14, desc: "Espelho do Ponto", name: "ESPELHO_PONTO" },
  EXAME_ADMINISSIONAL_DEMISSIONAL: {
    cod: 10,
    desc: "Exame Admissional/Demissional",
    name: "EXAME_ADMINISSIONAL_DEMISSIONAL",
  },

  FERIAS: { cod: 13, desc: "Férias", name: "FERIAS" },
  HOLERITE: { cod: 1, desc: "Holerite", name: "HOLERITE" },

  INFORME_DE_RENDIMENTOS: {
    cod: 4,
    desc: "Informe de Rendimentos",
    name: "INFORME_DE_RENDIMENTOS",
  },
  OUTROS: { cod: 11, desc: "Outros", name: "OUTROS" },
  PLR: { cod: 7, desc: "PLR", name: "PLR" },
  RECIBOS: { cod: 6, desc: "Recibos", name: "RECIBOS" },
  SALARIO_13: { cod: 5, desc: "13º Salário", name: "SALARIO_13" },
});

export const DOMINIO_TIPO_ARQUIVO_CLIENTE = Object.freeze({
  CNH_RG:{
    cod: 1,
    desc: "RG ou CNH",
    name: "CNH_RG",
  },
  COMPROVANTE_DE_RESIDENCIA:{
    cod: 2,
    desc: "Comprovante de residência",
    name: "COMPROVANTE_DE_RESIDENCIA",
  },
  CARTAO_CNPJ:{
    cod: 3,
    desc: "Cartão cnpj",
    name: "CARTAO_CNPJ",
  },
  CONTRATO_SOCIAL:{
    cod: 4,
    desc: "Contrato social",
    name: "CONTRATO_SOCIAL",
  },
});

export const SITUACAO_EMPREGADO = [
  {
    cod: "ATIVO",
    name: "Ativo",
  },
  {
    cod: "INATIVO_APOSENTADO",
    name: "Inativo/Aposentado",
  },
  {
    cod: "PENSIONISTA",
    name: "Pensionista",
  },
];

export const REGIME_JURIDICO = [
  {
    cod: "CLT",
    name: "CLT",
  },
  {
    cod: "ESTATUTARIO",
    name: "Estatutário",
  },
];

export const langHighcharts = {
  decimalPoint: ",",
  thousandsSep: ".",
  contextButtonTitle: "Menu do Gráfico",
  downloadCSV: "Download CSV",
  downloadJPEG: "Download JPEG",
  downloadPDF: "Download PDF",
  downloadPNG: "Download PNG",
  downloadSVG: "Download SVG",
  downloadXLS: "Download XLS",
  drillUpText: "Voltar Para {series.name}",
  loading: "Carregando...",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  navigation: {
    popup: {
      addButton: "Adicionar",
      background: "Fundo",
    },
  },
  tooltip: {
    yDecimals: 2,
  },
  noData: "Nenhum dado para exibir",
  openInCloud: "Abrir no Highcharts Cloud",
  printChart: "Imprimir Gráfico",
  viewFullscreen: "Visualizar em tela cheia",
  resetZoom: "Resetar zoom",
  resetZoomTitle: "Resetar zoom nível 1:1",
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  viewData: "Visualizar dados",
  weekdays: [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ],
};

export const TIPO_CONTA = [
  {
    cod: "CORRENTE",
    name: "Conta Corrente",
  },
  {
    cod: "POUPANCA",
    name: "Conta Poupança",
  },
];

export const TIPO_CHAVE = [
  {
    cod: "CPF",
    name: "CPF",
  },
  {
    cod: "CNPJ",
    name: "CNPJ",
  },
  {
    cod: "EMAIL",
    name: "Email",
  },
  {
    cod: "TELEFONE",
    name: "Telefone",
  },
  {
    cod: "ALEATORIO",
    name: "Chave Aleatória",
  },
];

export const RACA = [
  {
    cod: "NENHUM",
    name: "Nenhum",
  },
  {
    cod: "INDIGENA",
    name: "Indígena",
  },
  {
    cod: "BRANCA",
    name: "Branca",
  },
  {
    cod: "PRETA",
    name: "Preta",
  },
  {
    cod: "AMARELA",
    name: "Amarela",
  },
  {
    cod: "PARDA",
    name: "Parda",
  },
];

export const ESTADO_CIVIL = [
  {
    cod: "CASADO",
    name: "Casado",
  },
  {
    cod: "SOLTEIRO",
    name: "Solteiro",
  },
  {
    cod: "SEPARADO",
    name: "Separado",
  },
  {
    cod: "VIUVO",
    name: "Viúvo",
  },
  {
    cod: "DIVORCIADO",
    name: "Divorciado",
  },
  {
    cod: "UNIAO_ESTAVEL",
    name: "União Estável",
  },
];

export const SEXO = [
  {
    cod: "MASCULINO",
    name: "Masculino",
  },
  {
    cod: "FEMININO",
    name: "Feminino",
  },
  {
    cod: "NAO_BINARIO",
    name: "Não-Binário",
  },
];

export const STATUS_CONTRATO = [
  {
    cod: "PROPOSTA",
    name: "Proposta",
  },
  {
    cod: "COMPLIANCE",
    name: "Compliance",
  },
  {
    cod: "ACEITO",
    name: "Aceito",
  },
  {
    cod: "RECUSADO",
    name: "Recusado",
  },
  {
    cod: "FINALIZADO",
    name: "Finalizado",
  },
];

export const TIPO_PESSOA = [
  {
    cod: "FISICA",
    name: "Fisica",
  },
  {
    cod: "JURIDICA",
    name: "Juridica",
  },
];

export const ESCOLARIDADE = [
  "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou",
  "Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular",
  "5º ano completo do Ensino Fundamental",
  "Do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª à 8ª série)",
  "Ensino Fundamental completo",
  "Ensino Médio incompleto",
  "Ensino Médio completo",
  "Educação Superior incompleta",
  "Educação Superior completa",
  "Mestrado completo",
  "Doutorado completo",
  "Pós Graduação completa",
  "Técnico completo",
  "Técnologo completo",
  "Pós Graduação incompleta",
  "Mestrado incompleto",
  "Doutorado incompleto",
  "Técnico incompleto",
  "Técnologo incompleto",
];

export const RELACAO = [
  "Cônjuge",
  "Companheiro com filhos e/ou união estável",
  "Filho ou enteado até 21 anos",
  "Filho ou enteado universitário ou escola técnica",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola ténica",
  "Pais, avós e bisavós",
  "Menor pobre, até 21 anos",
  "Incapaz",
  "Companheiro sem filhos e/ou união estável",
  "Agregado/Outros",
  "Ex cônjuge que receba pensão de alimentos",
  "Filhos maiores de 24 anos",
];

export const BANCO = [
  {
    cod: "BANCO_DO_BRASIL",
    name: "Banco do Brasil S.A.",
  },
  {
    cod: "BANCO_DA_AMAZONIA",
    name: "Banco da Amazônia S.A.",
  },
  {
    cod: "BANCO_DO_NORDESTE",
    name: "Banco do Nordeste do Brasil S.A.",
  },
  {
    cod: "BNDES",
    name: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
  },
  {
    cod: "CREDICOAMO",
    name: "CREDICOAMO CREDITO RURAL COOPERATIVA",
  },
  {
    cod: "CREDIT",
    name: "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
  },
  {
    cod: "Banco_INBURSA",
    name: "Banco Inbursa S.A.",
  },
  {
    cod: "STATE_STREET_BRASIL",
    name: "State Street Brasil S.A. – Banco Comercial",
  },
  {
    cod: "UBS_CORRETORA_CAMBIO",
    name: "UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    cod:
      "COOPERATIVA_DE_CREDITO_MUTUO_DOS_DESPACHANTES_DE_TRANSITO_DE_SANTA_CATARINA",
    name:
      "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
  },
  {
    cod: "BNY",
    name: "BNY Mellon Banco S.A.",
  },
  {
    cod: "TRICURY",
    name: "Banco Tricury S.A.",
  },
  {
    cod: "BANESTES",
    name: "BANESTES S.A. Banco do Estado do Espírito Santo",
  },
  {
    cod: "BANDEPE",
    name: "Banco BANDEPE S.A.",
  },
  {
    cod: "ALFA",
    name: "Banco Alfa S.A.",
  },
  {
    cod: "ITAU_CONSIGNADO",
    name: "Banco Itaú Consignado S.A.",
  },
  {
    cod: "SANTANDER",
    name: "Banco Santander (Brasil) S.A.",
  },
  {
    cod: "BRADESCO_BBI",
    name: "Banco Bradesco BBI S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_PARA",
    name: "Banco do Estado do Pará S.A.",
  },
  {
    cod: "CARGILL",
    name: "Banco Cargill S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_RIO_GRANDE_DO_SUL",
    name: "Banco do Estado do Rio Grande do Sul S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DE_SERGIPE",
    name: "Banco do Estado de Sergipe S.A.",
  },
  {
    cod: "CONFIDENCE",
    name: "Confidence Corretora de Câmbio S.A.",
  },
  {
    cod: "HIPERCARD",
    name: "Hipercard Banco Múltiplo S.A.",
  },
  {
    cod: "BRADESCARD",
    name: "Banco Bradescard S.A.",
  },
  {
    cod: "GOLDMAN",
    name: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
  },
  {
    cod: "ANDBANK",
    name: "Banco Andbank (Brasil) S.A.",
  },
  {
    cod: "MORGAN",
    name: "Banco Morgan Stanley S.A.",
  },
  {
    cod: "CREFISA",
    name: "Banco Crefisa S.A.",
  },
  {
    cod: "BRB",
    name: "BRB – Banco de Brasília S.A.",
  },
  {
    cod: "J_SAFRA",
    name: "Banco J. Safra S.A.",
  },
  {
    cod: "ABN_AMRO",
    name: "Banco ABN AMRO S.A.",
  },
  {
    cod: "KDB",
    name: "Banco KDB S.A.",
  },
  {
    cod: "INTER",
    name: "Banco Inter S.A.",
  },
  {
    cod: "HAITONG",
    name: "Haitong Banco de Investimento do Brasil S.A.",
  },
  {
    cod: "ORIGINAL_AGRONEGOCIO",
    name: "Banco Original do Agronegócio S.A.",
  },
  {
    cod: "BT",
    name: "B&T CORRETORA DE CAMBIO LTDA.",
  },
  {
    cod: "BANCOSEGURO",
    name: "BancoSeguro S.A.",
  },
  {
    cod: "TOPAZIO",
    name: "Banco Topázio S.A.",
  },
  {
    cod: "CHINA_BRASIL",
    name: "Banco da China Brasil S.A.",
  },
  {
    cod: "UNIPRIME_NORTE_PARANA",
    name:
      "Uniprime Norte do Paraná – Coop de Economia e Crédito Mútuo dos Médicos",
  },
  {
    cod: "AILOS",
    name: "Cooperativa Central de Crédito – AILOS",
  },
  {
    cod: "CREDISAN",
    name: "CREDISAN COOPERATIVA DE CRÉDITO",
  },
  {
    cod:
      "CENTRAL_DE_COOPERATIVAS_DE_ECONOMIA_CREDITO_MUTUO_DO_ESTADO_DO_RIO_GRANDE",
    name:
      "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
  },
  {
    cod: "BRICKELL",
    name: "Brickell S.A. Crédito",
  },
  {
    cod: "POLOCRED",
    name:
      "PÓLOCRED SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT",
  },
  {
    cod: "FINAXIS",
    name: "Banco Finaxis S.A.",
  },
  {
    cod: "TRAVELEX",
    name: "Travelex Banco de Câmbio S.A.",
  },
  {
    cod: "B3",
    name: "Banco B3 S.A.",
  },
  {
    cod: "COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO",
    name: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda.",
  },
  {
    cod: "CREDIALIANCA",
    name: "Credialiança Cooperativa de Crédito Rural",
  },
  {
    cod: "UNIPRIME_CENTRAL",
    name:
      "UNIPRIME CENTRAL – CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.",
  },
  {
    cod: "PLANNER",
    name: "Planner Corretora de Valores S.A.",
  },
  {
    cod: "RENASCENCA",
    name: "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "XP_INVESTIMENTOS",
    name:
      "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
  },
  {
    cod: "CAIXA",
    name: "Caixa Econômica Federal",
  },
  {
    cod: "LECCA",
    name: "Lecca Crédito, Financiamento e Investimento S/A",
  },
  {
    cod: "BOCOM",
    name: "Banco BOCOM BBM S.A.",
  },
  {
    cod: "PORTOCRED",
    name: "PORTOCRED S.A. – CREDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cod: "OLIVEIRA",
    name: "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
  },
  {
    cod: "MAGLIANO",
    name: "Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
  },
  {
    cod: "CECOOP",
    name: "Central Cooperativa de Crédito no Estado do Espírito Santo – CECOOP",
  },
  {
    cod: "ADVANCED",
    name: "ADVANCED CORRETORA DE CÂMBIO LTDA",
  },
  {
    cod: "WESTERN",
    name: "Banco Western Union do Brasil S.A.",
  },
  {
    cod: "RODOBENS",
    name: "Banco Rodobens S.A.",
  },
  {
    cod: "AGIBANK",
    name: "Banco Agibank S.A.",
  },
  {
    cod: "BRADESCO_BERJ",
    name: "Banco Bradesco BERJ S.A.",
  },
  {
    cod: "WOORI_BANK",
    name: "Banco Woori Bank do Brasil S.A.",
  },
  {
    cod: "PLURAL",
    name: "Plural S.A. – Banco Múltiplo",
  },
  {
    cod: "BR_PARTNERS",
    name: "BR Partners Banco de Investimento S.A.",
  },
  {
    cod: "CODEPE",
    name: "Codepe Corretora de Valores e Câmbio S.A.",
  },
  {
    cod: "MS_BANK",
    name: "MS Bank S.A. Banco de Câmbio",
  },
  {
    cod: "UBS_BRASIL",
    name: "UBS Brasil Banco de Investimento S.A.",
  },
  {
    cod: "CARUANA",
    name: "CARUANA S.A. – SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cod: "TULLETT",
    name: "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
  },
  {
    cod: "ICBC",
    name: "ICBC do Brasil Banco Múltiplo S.A.",
  },
  {
    cod: "CONFEDERACAO_NACIONAL_DAS_COOPERATIVAS_CENTRAIS",
    name:
      "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E",
  },
  {
    cod: "BGC",
    name: "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "UNICRED",
    name:
      "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. – UNICRED DO BRASI",
  },
  {
    cod: "GET_MONEY",
    name: "Get Money Corretora de Câmbio S.A.",
  },
  {
    cod: "INTESA",
    name: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
  },
  {
    cod: "EASYNVEST",
    name: "Easynvest – Título Corretora de Valores SA",
  },
  {
    cod: "BROKER",
    name: "Broker Brasil Corretora de Câmbio Ltda.",
  },
  {
    cod: "TREVISO",
    name: "Treviso Corretora de Câmbio S.A.",
  },
  {
    cod: "BEXS",
    name: "BEXS Banco de Câmbio S.A.",
  },
  {
    cod: "LEVYCAM",
    name: "LEVYCAM – CORRETORA DE CAMBIO E VALORES LTDA.",
  },
  {
    cod: "GUITTA",
    name: "GUITTA CORRETORA DE CAMBIO LTDA.",
  },
  {
    cod: "FACTA",
    name: "Facta Financeira S.A. – Crédito Financiamento e Investimento",
  },
  {
    cod: "ICAP",
    name: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    cod: "CASA_DO_CREDITO",
    name: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
  },
  {
    cod: "COMMERZBANK",
    name: "Commerzbank Brasil S.A. – Banco Múltiplo",
  },
  {
    cod: "OLE",
    name: "Banco Olé Bonsucesso Consignado S.A.",
  },
  {
    cod: "BRL_TRUST",
    name: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "PERNAMBUCANAS",
    name:
      "PERNAMBUCANAS FINANCIADORA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cod: "GUIDE",
    name: "Guide Investimentos S.A. Corretora de Valores",
  },
  {
    cod: "CM_CAPITAL",
    name:
      "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "SOCRED",
    name:
      "SOCRED S.A. – SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P",
  },
  {
    cod: "ITAU",
    name: "Banco Itaú BBA S.A.",
  },
  {
    cod: "ATIVA",
    name: "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES",
  },
  {
    cod: "HS",
    name: "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
  },
  {
    cod: "SERVICOOP",
    name:
      "SERVICOOP – COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN",
  },
  {
    cod: "NOVA_FUTURA",
    name: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    cod: "PARMETAL",
    name: "PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "FAIR",
    name: "FAIR CORRETORA DE CAMBIO S.A.",
  },
  {
    cod: "STONE",
    name: "Stone Pagamentos S.A.",
  },
  {
    cod: "BTG",
    name: "Banco BTG Pactual S.A.",
  },
  {
    cod: "ORIGINAL",
    name: "Banco Original S.A.",
  },
  {
    cod: "ARBI",
    name: "Banco Arbi S.A.",
  },
  {
    cod: "JOHN",
    name: "Banco John Deere S.A.",
  },
  {
    cod: "BS2",
    name: "Banco BS2 S.A.",
  },
  {
    cod: "CREDIT_AGRICOLE",
    name: "Banco Credit Agricole Brasil S.A.",
  },
  {
    cod: "FIBRA",
    name: "Banco Fibra S.A.",
  },
  {
    cod: "CIFRA",
    name: "Banco Cifra S.A.",
  },
  {
    cod: "NEXT",
    name: "Next",
  },
  {
    cod: "BRADESCO",
    name: "Banco Bradesco S.A.",
  },
  {
    cod: "CLASSICO",
    name: "Banco Clássico S.A.",
  },
  {
    cod: "MAXIMA",
    name: "Banco Máxima S.A.",
  },
  {
    cod: "ABC",
    name: "Banco ABC Brasil S.A.",
  },
  {
    cod: "INVESTCRED",
    name: "Banco Investcred Unibanco S.A.",
  },
  {
    cod: "BCV",
    name: "BCV – Banco de Crédito e Varejo S.A.",
  },
  {
    cod: "BEXS_CORRETORA",
    name: "Bexs Corretora de Câmbio S/A",
  },
  {
    cod: "PARANA",
    name: "Paraná Banco S.A.",
  },
  {
    cod: "MONEYCORP",
    name: "MONEYCORP BANCO DE CÂMBIO S.A.",
  },
  {
    cod: "NUBANK",
    name: "NU Pagamentos S.A. – Nubank",
  },
  {
    cod: "FATOR",
    name: "Banco Fator S.A.",
  },
  {
    cod: "CEDULA",
    name: "Banco Cédula S.A.",
  },
  {
    cod: "BARI_COMPANHIA",
    name: "BARI COMPANHIA HIPOTECÁRIA",
  },
  {
    cod: "CELCOIN",
    name: "CELCOIN INSTITUICAO DE PAGAMENTO S.A.",
  },
  {
    cod: "HSBC",
    name: "HSBC Brasil S.A. – Banco de Investimento",
  },
  {
    cod: "SAGITUR",
    name: "Sagitur Corretora de Câmbio Ltda.",
  },
  {
    cod: "IB",
    name: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "AGK",
    name: "AGK CORRETORA DE CAMBIO S.A.",
  },
  {
    cod: "SULCREDI",
    name:
      "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel",
  },
  {
    cod: "MONEY",
    name:
      "MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
  },
  {
    cod: "SENFF",
    name: "Senff S.A. – Crédito, Financiamento e Investimento",
  },
  {
    cod: "GENIAL",
    name: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_RURAL_DE_PRIMAVERA_DO_LESTE",
    name: "COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
  },
  {
    cod: "AVISTA",
    name: "Avista S.A. Crédito, Financiamento e Investimento",
  },
  {
    cod: "COOPAVEL",
    name: "Cooperativa de Crédito Rural Coopavel",
  },
  {
    cod: "RB",
    name:
      "RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA",
  },
  {
    cod: "FRENTE_CORRETORA_DE_CAMBIO",
    name: "Frente Corretora de Câmbio Ltda.",
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_RURAL_DE_OURO",
    name: "COOPERATIVA DE CRÉDITO RURAL DE OURO SULCREDI/OURO",
  },
  {
    cod: "CAROL",
    name: "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    cod: "DECYSEO",
    name: "DECYSEO CORRETORA DE CAMBIO LTDA.",
  },
  {
    cod: "PAGSEGURO",
    name: "Pagseguro Internet S.A. – PagBank",
  },
  {
    cod: "BS2_TITULOS_MOBILIARIOS",
    name: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "LASTRO",
    name: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    cod: "VISION",
    name: "VISION S.A. CORRETORA DE CAMBIO",
  },
  {
    cod: "VIPS",
    name: "Vip’s Corretora de Câmbio Ltda.",
  },
  {
    cod: "SOROCRED",
    name: "SOROCRED CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    cod: "LA_NACION",
    name: "Banco de La Nacion Argentina",
  },
  {
    cod: "BPP",
    name: "BPP Instituição de Pagamento S.A.",
  },
  {
    cod: "PORTOPAR",
    name: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    cod: "TERRA",
    name:
      "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
  },
  {
    cod: "CAMBIONET",
    name: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
  },
  {
    cod: "VORTX",
    name: "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
  },
  {
    cod: "PI",
    name: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "BMG",
    name: "Banco BMG S.A.",
  },
  {
    cod: "OM",
    name: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "CHINA",
    name: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
  },
  {
    cod: "CREFAZ",
    name:
      "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
  },
  {
    cod: "CREDILUZ",
    name: "Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz",
  },
  {
    cod: "MERCADOPAGO",
    name: "MERCADOPAGO.COM REPRESENTACOES LTDA.",
  },
  {
    cod: "ORAMA",
    name: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "PARATI",
    name: "PARATI – CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
  },
  {
    cod: "QI",
    name: "QI Sociedade de Crédito Direto S.A.",
  },
  {
    cod: "BARI_FINANCIAMENTOS",
    name: "Banco Bari de Investimentos e Financiamentos S/A",
  },
  {
    cod: "FRAM",
    name: "Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
  },
  {
    cod: "ACESSO_SOLUCOES",
    name: "Acesso Soluções de Pagamento S.A.",
  },
  {
    cod: "DIGIO",
    name: "Banco Digio S.A.",
  },
  {
    cod: "C6",
    name: "Banco C6 S.A – C6 Bank",
  },
  {
    cod: "SUPER",
    name: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
  },
  {
    cod: "ITAU_UNIBANCO",
    name: "Itaú Unibanco S.A.",
  },
  {
    cod: "CREDITAS",
    name: "Creditas Sociedade de Crédito Direto S.A.",
  },
  {
    cod: "FFA",
    name:
      "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
  },
  {
    cod: "XP",
    name: "Banco XP S.A.",
  },
  {
    cod: "AMAGGI",
    name: "AMAGGI S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cod: "TORO",
    name: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
  },
  {
    cod: "NECTON",
    name:
      "NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
  },
  {
    cod: "OTIMO",
    name: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    cod: "GERENCIANET",
    name: "GERENCIANET PAGAMENTOS DO BRASIL LTDA",
  },
  {
    cod: "SOLIDUS",
    name: "SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
  },
  {
    cod: "SOCIETE",
    name: "Banco Société Générale Brasil S.A.",
  },
  {
    cod: "VITREO",
    name: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
  },
  {
    cod: "CARREFOUR",
    name: "Banco Carrefour",
  },
  {
    cod: "MIZUHO",
    name: "Banco Mizuho do Brasil S.A.",
  },
  {
    cod: "UP",
    name: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
  },
  {
    cod: "J_P_MORGAN",
    name: "Banco J. P. Morgan S.A.",
  },
  {
    cod: "PICPAY",
    name: "PicPay",
  },
  {
    cod: "MERCANTIL",
    name: "Banco Mercantil do Brasil S.A.",
  },
  {
    cod: "BRADESCO_FINANCIAMENTO",
    name: "Banco Bradesco Financiamentos S.A.",
  },
  {
    cod: "KIRTON",
    name: "Kirton Bank S.A. – Banco Múltiplo",
  },
  {
    cod: "CAPITAL",
    name: "Banco Capital S.A.",
  },
  {
    cod: "SAFRA",
    name: "Banco Safra S.A.",
  },
  {
    cod: "MUFG",
    name: "Banco MUFG Brasil S.A.",
  },
  {
    cod: "SUMITOMO",
    name: "Banco Sumitomo Mitsui Brasileiro S.A.",
  },
  {
    cod: "CAIXA_GERAL",
    name: "Banco Caixa Geral – Brasil S.A.",
  },
  {
    cod: "CITIBANK_NA",
    name: "Citibank N.A.",
  },
  {
    cod: "ITAUBANK",
    name: "Banco ItauBank S.A",
  },
  {
    cod: "DEUTSCHE",
    name: "Deutsche Bank S.A. – Banco Alemão",
  },
  {
    cod: "JPMORGAN",
    name: "JPMorgan Chase Bank",
  },
  {
    cod: "ING",
    name: "ING Bank N.V.",
  },
  {
    cod: "LA_PROVINCIA",
    name: "Banco de La Provincia de Buenos Aires",
  },
  {
    cod: "CREDIT_SUISSE",
    name: "Banco Credit Suisse (Brasil) S.A.",
  },
  {
    cod: "SENSO",
    name: "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
  },
  {
    cod: "LUSO",
    name: "Banco Luso Brasileiro S.A.",
  },
  {
    cod: "INDUSTRIAL",
    name: "Banco Industrial do Brasil S.A.",
  },
  {
    cod: "VR",
    name: "Banco VR S.A.",
  },
  {
    cod: "PAULISTA",
    name: "Banco Paulista S.A.",
  },
  {
    cod: "GUANABARA",
    name: "Banco Guanabara S.A.",
  },
  {
    cod: "OMNI",
    name: "Omni Banco S.A.",
  },
  {
    cod: "PAN",
    name: "Banco PAN S.A.",
  },
  {
    cod: "FICSA",
    name: "Banco Ficsa S.A.",
  },
  {
    cod: "SMARTBANK",
    name: "Banco Smartbank S.A.",
  },
  {
    cod: "RENDIMENTO",
    name: "Banco Rendimento S.A.",
  },
  {
    cod: "TRIANGULO",
    name: "Banco Triângulo S.A.",
  },
  {
    cod: "SOFISA",
    name: "Banco Sofisa S.A.",
  },
  {
    cod: "PINE",
    name: "Banco Pine S.A.",
  },
  {
    cod: "ITAU_UNIBANCO_HOLDING",
    name: "Itaú Unibanco Holding S.A.",
  },
  {
    cod: "INDUSVAL",
    name: "Banco Indusval S.A.",
  },
  {
    cod: "RENNER",
    name: "Banco A.J.Renner S.A.",
  },
  {
    cod: "VOTORANTIM",
    name: "Banco Votorantim S.A.",
  },
  {
    cod: "DAYCOVAL",
    name: "Banco Daycoval S.A.",
  },
  {
    cod: "OURINVEST",
    name: "Banco Ourinvest S.A.",
  },
  {
    cod: "CETELEM",
    name: "Banco Cetelem S.A.",
  },
  {
    cod: "RIBEIRAO_PRETO",
    name: "Banco Ribeirão Preto S.A.",
  },
  {
    cod: "SEMEAR",
    name: "Banco Semear S.A.",
  },
  {
    cod: "CITIBANK",
    name: "Banco Citibank S.A.",
  },
  {
    cod: "MODAL",
    name: "Banco Modal S.A.",
  },
  {
    cod: "RABOBANK",
    name: "Banco Rabobank International Brasil S.A.",
  },
  {
    cod: "SICREDI",
    name: "Banco Cooperativo Sicredi S.A.",
  },
  {
    cod: "SCOTIABANK",
    name: "Scotiabank Brasil S.A. Banco Múltiplo",
  },
  {
    cod: "BNP",
    name: "Banco BNP Paribas Brasil S.A.",
  },
  {
    cod: "CONTINENTAL",
    name: "Novo Banco Continental S.A. – Banco Múltiplo",
  },
  {
    cod: "SISTEMA",
    name: "Banco Sistema S.A.",
  },
  {
    cod: "MERRILL",
    name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
  },
  {
    cod: "BANCOOB",
    name: "Banco Cooperativo do Brasil S.A. – BANCOOB",
  },
  {
    cod: "KEB",
    name: "Banco KEB HANA do Brasil S.A.",
  },
];

export const ESTADOS_JSON = [
  {
    nome: "Acre",
    sigla: "AC",
  },
  {
    nome: "Alagoas",
    sigla: "AL",
  },
  {
    nome: "Amapá",
    sigla: "AP",
  },
  {
    nome: "Amazonas",
    sigla: "AM",
  },
  {
    nome: "Bahia",
    sigla: "BA",
  },
  {
    nome: "Ceará",
    sigla: "CE",
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
  },
  {
    nome: "Goiás",
    sigla: "GO",
  },
  {
    nome: "Maranhão",
    sigla: "MA",
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
  },
  {
    nome: "Pará",
    sigla: "PA",
  },
  {
    nome: "Paraíba",
    sigla: "PB",
  },
  {
    nome: "Paraná",
    sigla: "PR",
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
  },
  {
    nome: "Piauí",
    sigla: "PI",
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
  },
  {
    nome: "Rondônia",
    sigla: "RO",
  },
  {
    nome: "Roraima",
    sigla: "RR",
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
  },
  {
    nome: "São Paulo",
    sigla: "SP",
  },
  {
    nome: "Sergipe",
    sigla: "SE",
  },
  {
    nome: "Tocantins",
    sigla: "TO",
  },
];


export const STATUS_CONTRATO_CONTEA = [
  {
    value: "PROPOSTA",
    desc: "Proposta"
  },
  {
    value: "COMPLIANCE",
    desc: "Compliance"
  },
  {
    value: "ACEITO",
    desc: "Aceito"
  },
  {
    value: "RECUSADO",
    desc: "Recusado"
  },
];

export const STATUS_CONTRATO_CONTEA_APROVACAO = [
  {
    value: "COMPLIANCE",
    desc: "Compliance"
  },
  {
    value: "ACEITO",
    desc: "Aceito"
  },
  {
    value: "RECUSADO",
    desc: "Recusado"
  },
];

export const TIPO_EMPRESA = [
  {
    nome: "Matriz",
    sigla: "MATRIZ",
  },
  {
    nome: "Filial",
    sigla: "FILIAL",
  },
  {
    nome: "Representante",
    sigla: "REPRESENTANTE",
  },
];

export const DOMINIO_TIPO_REPETE_EVENTO = Object.freeze({
  NUNCA: { cod: 1, desc: "Uma Vez", name: "NUNCA" },
  MENSAL: { cod: 2, desc: "Mensal", name: "MENSAL" },
  ANUAL: { cod: 3, desc: "Anual", name: "ANUAL" },
});

export const DOMINIO_TIPO_EVENTO_CALENDARIO = Object.freeze({
  FERIADO: { cod: 1, desc: "Feriado", name: "FERIADO" },
  ATUALIZACAO_SISTEMA: {
    cod: 5,
    desc: "Atualização do Sistema",
    name: "ATUALIZACAO_SISTEMA",
  },
  ACONTECIMENTO: { cod: 6, desc: "Acontecimento", name: "ACONTECIMENTO" },
});

export const MESSAGE_VALIDATION_INPUT = {
  REQUERID_FIELD: "Campo Obrigatório!",
  INVALID_EMAIL_FIELD: "Email Inválido!",
  INVALID_DATE: "Formato de data inválida!",
};

export const DOMINIO_STATUS_COLABORADOR = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativo" },
  INATIVA: { cod: "INATIVA", name: "Inativo" },
});

export const DOMINIO_STATUS_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: "ATIVO", name: "Ativo" },
  INATIVO: { cod: "INATIVO", name: "Inativo" },
});

export const DOMINIO_STATUS_EMPRESA = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativa" },
  SUSPENSA: { cod: "SUSPENSA", name: "Suspensa" },
  INATIVA: { cod: "INATIVA", name: "Inativa" },
});

export const DOMINIO_TIPO_PLANO = Object.freeze({
  BASIC: { cod: "BASIC", name: "Essencial" },
  STANDARD: { cod: "STANDARD", name: "Avançado" },
});

export const DOMINIO_TIPO_FATURAMENTO = Object.freeze({
  SIMPLES_NACIONAL: {
    cod: 0,
    desc: "Simples Nacional",
    name: "SIMPLES_NACIONAL",
  },
  LUCRO_REAL_PRESUMIDO: {
    cod: 1,
    desc: "Lucro Real/Presumido",
    name: "LUCRO_REAL_PRESUMIDO",
  },
});

export const DOMINIO_SIM_NAO = Object.freeze({
  SIM: { cod: 1, desc: "Sim", name: "SIM" },
  NAO: { cod: 0, desc: "Não", name: "NAO" },
});

export const DOMINIO_TIPO_RENDIMENTO = Object.freeze({
  QUINZENAL: { cod: 15, desc: "Quinzenal", name: "QUINZENAL" },
  MENSAL: { cod: 30, desc: "Mensal", name: "MENSAL" },
  TRIMESTRAL: { cod: 180, desc: "Trimestral", name: "TRIMESTRAL" },
  SEMESTRAL: { cod: 183, desc: "Semestral", name: "SEMESTRAL" },
  ANUAL: { cod: 365, desc: "Anual", name: "ANUAL" },
});

export const DominioStatusDescontoParcela = Object.freeze({
  SUCESSO: { cod: 1, desc: "Sucesso", name: "SUCESSO" },
  FALHA: { cod: 2, desc: "Falha", name: "FALHA" },
  PARCIAL: { cod: 3, desc: "Parcial", name: "PARCIAL" },
  NAO_PROCESSADA: { cod: 4, desc: "Não Processada", name: "NAO_PROCESSADA" },
});

export const DOMINIO_SEVERITY_MENSAGEM = Object.freeze({
  ERROR: { cod: "error", desc: "Erro", name: "error" },
  WARNING: { cod: "warning", desc: "Atenção", name: "warning" },
  INFO: { cod: "info", desc: "Informativa", name: "info" },
  SUCCESS: { cod: "success", desc: "Sucesso", name: "success" },
});

export const DOMINIO_TIPO_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  CONSULTOR: { cod: 3, desc: "Consultor", name: "GESTOR" },
  RECRUTAMENTO: { cod: 6, desc: "Recrutamento", name: "RECRUTAMENTO" },
  CLIENTE: { cod: 5, desc: "Cliente", name: "CLIENTE" },
});

export const DOMINIO_TIPO_NOTIFICACAO = Object.freeze({
  EMAIL: { cod: 1, desc: "E-mail", name: "EMAIL" },
  SISTEMA: { cod: 2, desc: "Sistema", name: "SISTEMA" },
  PUSH: { cod: 4, desc: "PUSH (Via Aplicativo)", name: "PUSH" },
});

export const DOMINIO_STATUS_CONTRATO = Object.freeze({
  VIGENTE: { cod: 1, desc: "Vigênte", name: "VIGENTE" },
  REJEITADO: { cod: 2, desc: "Rejeitado", name: "REJEITADO" },
  ENCERADO: { cod: 3, desc: "Encerrado", name: "ENCERADO" },
  RENOVADO: { cod: 4, desc: "Renovado", name: "RENOVADO" },
  EM_RENOVACAO: { cod: 8, desc: "Em Renovação", name: "EM_RENOVACAO" },
  CONFIRMACAO: { cod: 5, desc: "Confirmação", name: "CONFIRMACAO" },
  AGUARDANDO_ASSINATURA: {
    cod: 6,
    desc: "Aguardando Assinatura",
    name: "AGUARDANDO_ASSINATURA",
  },
  ANTECIPACAO_SOLICITADA: {
    cod: 7,
    desc: "Solicitado Resgate",
    name: "ANTECIPACAO_SOLICITADA",
  },
  NEGOCIACAO: { cod: 10, desc: "Negociação", name: "NEGOCIACAO" },
});

export const DOMINIO_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Inativo", name: "INATIVO" },
});

export const DOMINIO_STATUS_LOTE = Object.freeze({
  GERADO: { cod: 1, desc: "Gerado", name: "GERADO" },
  CONFIRMADO: { cod: 2, desc: "Confirmado", name: "CONFIRMADO" },
  INATIVO: { cod: 0, desc: "Inativo", name: "INATIVO" },
});

export const DOMINIO_ATIVO_INATIVO_COLABORADOR = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Desligado", name: "INATIVO" },
});

export const DOMINIO_PAPEL_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  COLABORADOR: { cod: 3, desc: "Colaborador", name: "COLABORADOR" },
  RECRUTAMENTO: { cod: 6, desc: "Recrutamento", name: "RECRUTAMENTO" },
});

export const DOMINIO_PUBLICO_ALVO_MENSAGEM = Object.freeze({
  INDIVIDUAL: { cod: 1, desc: "Individual", name: "INDIVIDUAL" },
  TODOS: { cod: 2, desc: "Todos", name: "TODOS" },
  COLABORADORES: { cod: 5, desc: "Colaboradores", name: "COLABORADORES" },
  ADMINISTRADORES: { cod: 3, desc: "Administradores", name: "ADMINISTRADORES" },
  RECRUTAMENTO: { cod: 6, desc: "Recrutamento", name: "RECRUTAMENTO" },
  GESTORES: { cod: 4, desc: "Gestores", name: "GESTORES" },
});

export const DOMINIO_TIPO_ARQUIVO = Object.freeze({
  COMPROVANTE_DEPOSITO: {
    cod: 1,
    desc: "Comprovante de Depósito",
    name: "COMPROVANTE_DEPOSITO",
  },
  COMPROVANTE_CONTA_CONJUNTA: {
    cod: 2,
    desc: "Comprovante de Conta Conjunta",
    name: "COMPROVANTE_CONTA_CONJUNTA",
  },
  SOLICITACAO_RESGATE: {
    cod: 3,
    desc: "Solicitação de Resgate",
    name: "SOLICITACAO_RESGATE",
  },
});

export const DOMINIO_STATUS_MUTUARIO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  DESLIGADO: { cod: 2, desc: "Desligado", name: "DESLIGADO" },
});

export const DOMINIO_FUSO_HORARIO = Object.freeze({
  UTC_DOZE: { cod: 12, desc: "12", longDesc: "(GMT 12:00)", name: "UTC_DOZE" },
  UTC_ONZE: { cod: 11, desc: "11", longDesc: "(GMT 11:00)", name: "UTC_ONZE" },
  UTC_DEZ: { cod: 10, desc: "10", longDesc: "(GMT 10:00)", name: "UTC_DEZ" },
  UTC_NOVE: { cod: 9, desc: "9", longDesc: "(GMT 09:00)", name: "UTC_NOVE" },
  UTC_OITO: { cod: 8, desc: "8", longDesc: "(GMT 08:00)", name: "UTC_OITO" },
  UTC_SETE: { cod: 7, desc: "7", longDesc: "(GMT 07:00)", name: "UTC_SETE" },
  UTC_SEIS: { cod: 6, desc: "6", longDesc: "(GMT 06:00)", name: "UTC_SEIS" },
  UTC_CINCO: { cod: 5, desc: "5", longDesc: "(GMT 05:00)", name: "UTC_CINCO" },
  UTC_QUATRO: {
    cod: 4,
    desc: "4",
    longDesc: "(GMT 04:00)",
    name: "UTC_QUATRO",
  },
  UTC_TRES: { cod: 3, desc: "3", longDesc: "(GMT 03:00)", name: "UTC_TRES" },
  UTC_DOIS: { cod: 2, desc: "2", longDesc: "(GMT 02:00)", name: "UTC_DOIS" },
  UTC_UM: { cod: 1, desc: "1", longDesc: "(GMT 01:00)", name: "UTC_UM" },
  UTC_ZERO: { cod: 0, desc: "0", longDesc: "(GMT 00:00)", name: "UTC_ZERO" },
  UTC_MENOS_UM: {
    cod: -1,
    desc: "-1",
    longDesc: "(GMT -01:00)",
    name: "UTC_MENOS_UM",
  },
  UTC_MENOS_DOIS: {
    cod: -2,
    desc: "-2",
    longDesc: "(GMT -02:00) Horário de Fernando de Noronha",
    name: "UTC_MENOS_DOIS",
  },
  UTC_MENOS_TRES: {
    cod: -3,
    desc: "-3",
    longDesc: "(GMT -03:00) Horário de Brasília",
    name: "UTC_MENOS_TRES",
  },
  UTC_MENOS_QUATRO: {
    cod: -4,
    desc: "-4",
    longDesc: "(GMT -04:00) Horário da Amazônia",
    name: "UTC_MENOS_QUATRO",
  },
  UTC_MENOS_CINCO: {
    cod: -5,
    desc: "-5",
    longDesc: "(GMT -05:00) Horário do Acre",
    name: "UTC_MENOS_CINCO",
  },
  UTC_MENOS_SEIS: {
    cod: -6,
    desc: "-6",
    longDesc: "(GMT -06:00)",
    name: "UTC_MENOS_SEIS",
  },
  UTC_MENOS_SETE: {
    cod: -7,
    desc: "-7",
    longDesc: "(GMT -07:00)",
    name: "UTC_MENOS_SETE",
  },
  UTC_MENOS_OITO: {
    cod: -8,
    desc: "-8",
    longDesc: "(GMT -08:00)",
    name: "UTC_MENOS_OITO",
  },
  UTC_MENOS_NOVE: {
    cod: -9,
    desc: "-9",
    longDesc: "(GMT -09:00)",
    name: "UTC_MENOS_NOVE",
  },
  UTC_MENOS_DEZ: {
    cod: -10,
    desc: "-10",
    longDesc: "(GMT -10:00)",
    name: "UTC_MENOS_DEZ",
  },
  UTC_MENOS_ONZE: {
    cod: -11,
    desc: "-11",
    longDesc: "(GMT -11:00)",
    name: "UTC_MENOS_ONZE",
  },
  UTC_MENOS_DOZE: {
    cod: -12,
    desc: "-12",
    longDesc: "(GMT -12:00)",
    name: "UTC_MENOS_DOZE",
  },
});

export const DOMINIO_TIPO_CAMPO = Object.freeze({
  TEXTO: {
    preDefinido: false,
    cod: 1,
    desc: "Texto",
    name: "TEXTO",
    icon: <BsInputCursorText size={20} />,
  },
  TEXTO_LONGO: {
    preDefinido: false,
    cod: 2,
    desc: "Texto Longo",
    name: "TEXTO_LONGO",
    icon: <BsTextarea size={20} />,
  },
  NUMERO: {
    preDefinido: false,
    cod: 3,
    desc: "Número",
    name: "NUMERO",
    icon: <ImSortNumbericDesc size={20} />,
  },
  EMAIL: {
    preDefinido: false,
    cod: 4,
    desc: "Email",
    name: "EMAIL",
    icon: <AiOutlineMail size={20} />,
  },
  TELEFONE: {
    preDefinido: false,
    cod: 5,
    desc: "Telefone",
    name: "TELEFONE",
    icon: <AiOutlinePhone size={20} />,
  },
  CAIXA_SELECAO: {
    preDefinido: true,
    cod: 6,
    desc: "Caixa de Seleção",
    name: "CAIXA_SELECAO",
    icon: <VscListSelection size={20} />,
  },
  CLASSIFICACAO: {
    preDefinido: false,
    cod: 7,
    desc: "Classificação",
    name: "CLASSIFICACAO",
    icon: <BsStarHalf size={20} />,
  },
  DATA: {
    preDefinido: false,
    cod: 8,
    desc: "Data",
    name: "DATA",
    icon: <BsCalendarDate size={20} />,
  },
  MULTIPLA_ESCOLHA: {
    preDefinido: true,
    cod: 9,
    desc: "Múltipla Escolha",
    name: "MULTIPLA_ESCOLHA",
    icon: <BsListOl size={20} />,
  },
});
