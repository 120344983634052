function validaEmail(email) {
  if (email === undefined || email === null || email === "") {
    return false;
  }

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailPattern.test(email);
}

function validaCPF(strCPF) {
  if (!strCPF) {
    return false;
  }

  var strCPF = strCPF.replace(/[^\d]+/g, "");

  var Soma;
  var Resto;
  Soma = 0;
  if (
    strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999"
  )
    return false;

  for (var i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (var i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function validaPIS(pis) {
  var multiplicadorBase = "3298765432";
  var total = 0;
  var resto = 0;
  var multiplicando = 0;
  var multiplicador = 0;
  var digito = 99;

  // Retira a mascara
  var numeroPIS = pis.replace(/[^\d]+/g, "");

  if (
    numeroPIS.length !== 11 ||
    numeroPIS === "00000000000" ||
    numeroPIS === "11111111111" ||
    numeroPIS === "22222222222" ||
    numeroPIS === "33333333333" ||
    numeroPIS === "44444444444" ||
    numeroPIS === "55555555555" ||
    numeroPIS === "66666666666" ||
    numeroPIS === "77777777777" ||
    numeroPIS === "88888888888" ||
    numeroPIS === "99999999999"
  ) {
    return false;
  } else {
    for (var i = 0; i < 10; i++) {
      multiplicando = parseInt(numeroPIS.substring(i, i + 1));
      multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
      total += multiplicando * multiplicador;
    }

    resto = 11 - (total % 11);
    resto = resto === 10 || resto === 11 ? 0 : resto;

    digito = parseInt("" + numeroPIS.charAt(10));
    return resto === digito;
  }
}

function validarCNPJ(cnpj) {
  if (!cnpj) {
    return false;
  }

  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

const retorno = { validaEmail, validaCPF, validarCNPJ, validaPIS };

export default retorno;
