import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CadastroDadosBancarios from "./cadastro/CadastroDadosBancarios";
import { useEffect, useState } from "react";
import ContratoService from "../../../services/ContratoService";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import BreadcrumbsNav from "../../../components/icarus/BreadcrumbsNav";
import DataTable from "../../../components/datatable/DataTable";
import { Column } from "jspdf-autotable";
import ExcluirButtonTable from "../../../components/icarus/buttons/ExcluirButtonTable";
import EditarButtonTable from "../../../components/icarus/buttons/EditarButtonTable";
import MaskUtil from "../../../utils/MaskUtil";
import Loading from "../../../components/Loading";
import { Download, File, Loader, Printer, Send } from "react-feather";
import ClienteDocumentoService from "../../../services/ClienteDocumentoService";
import { Close, Verified } from "@mui/icons-material";
import PermissionUtil from "../../../utils/PermissionUtil";
import { useSelector } from "react-redux";
import { CgPrinter } from "react-icons/cg";

const DadosBancarios = () => {
  const { idMutuarioParam } = useParams();
  const [dadosBancariosId, setDadosBancariosId] = useState();
  const [carregandoContrato, setCarregandoContrato] = useState(false);
  const [carregandoDocumento, setCarregandoDocumento] = useState(false);
  const [listaDocumentos, setListaDocumentos] = useState();
  const [idCliente, setIdCliente] = useState();
  const [gerandoPrevia, setGerandoPrevia] = useState(false);
  const [gerandoDocumento, setGerandoDocumento] = useState(false);

  const [carregandoDadosBancarios, setCarregandoDadosBancarios] = useState(
    false
  );
  const [dadosContrato, setDadosBancarios] = useState({});
  const [objPage, setObjPage] = useState([]);
  const { user } = useSelector((state) => state.authReducer);

  const contrato = (idContrato) => {
    setCarregandoContrato(true);
    ContratoService.buscarContrato(idContrato)
      .then((response) => {
        setIdCliente(response.cliente.idCliente);
        setDadosBancarios(response);
      })
      .finally(() => setCarregandoContrato(false));
  };

  const pesquisar = (page, idContrato) => {
    setCarregandoDadosBancarios(true);
    ContratoService.buscarDadosBancariosPorContrato(page, idContrato)
      .then((response) => {
        setObjPage(response);
      })
      .finally(() => setCarregandoDadosBancarios(false));
  };

  useEffect(() => {
    pesquisarDocumentos(0);
  }, [idCliente]);

  const pesquisarDocumentos = (page) => {
    setCarregandoDocumento(true);
    ClienteDocumentoService.contrato(idMutuarioParam, page)
      .then((response) => {
        setListaDocumentos(response);
      })
      .finally(() => setCarregandoDocumento(false));
  };

  const apagarDadoBancario = (idDadoBancario) => {
    ContratoService.apagarDadoBancario(idDadoBancario).then(() => {
      pesquisar(0, idMutuarioParam);
    });
  };

  const onPageDataTable = (page) => {
    pesquisar(page, idMutuarioParam);
  };

  const actionTemplate = (rowData, column) => {
    return (
      <Grid container spacing={2}>
        <Grid item>
          <EditarButtonTable
            onClick={() => {
              setDadosBancariosId(rowData.idDadosBancarios);
            }}
          />
        </Grid>
        <Grid item>
          <ExcluirButtonTable
            onClick={() => {
              apagarDadoBancario(rowData.idDadosBancarios);
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const recusarContrato = (idContrato) => {
    ContratoService.recusarContrato(idContrato).then(() => {
      contrato(idMutuarioParam);
    });
  };

  const gerarDocumento = () => {
    ContratoService.gerarContrato(idMutuarioParam).then(
      (response) => {      
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
    
        const url = window.URL.createObjectURL(pdfBlob);
    
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", `${dadosContrato.cliente.nome}.pdf`);
    
        document.body.appendChild(tempLink);
        tempLink.click();
    
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      }).finally(() => {
        setGerandoDocumento(false);
        setGerandoPrevia(false);
      })
  };

  const aceitarContrato = (idContrato) => {
    ContratoService.aceitarContrato(idContrato).then(() => {
      contrato(idMutuarioParam);
    });
  };

  const RenderTable = () => {
    return (
      <DataTable
        carregando={carregandoDadosBancarios}
        objPage={objPage}
        onPage={onPageDataTable}
      >
        <Column
          field="BANCO"
          header="BANCO"
          body={(rowData) => (
            <>
              <span className="p-column-title">BANCO</span>
              {rowData?.banco?.nome}
            </>
          )}
        />

        <Column
          field="N. CONTA"
          header="N. CONTA"
          body={(rowData) => (
            <>
              <span className="p-column-title">N. CONTA</span>
              {[rowData?.conta, rowData?.contaDigito].filter(x => x).join("-")}
            </>
          )}
        />

        <Column
          field="AGÊNCIA"
          header="AGÊNCIA"
          body={(rowData) => (
            <>
              <span className="p-column-title">AGÊNCIA</span>
              {[rowData?.agencia, rowData?.agenciaDigito].filter(x => x).join("-")}
            </>
          )}
        />

        <Column body={actionTemplate} header="AÇÕES" />
      </DataTable>
    );
  };

  const ButtonDownload = ({ rowData }) => {
    const [fazendoDownload, setFazendoDownload] = useState(false);

    const download = (row) => {
      setFazendoDownload(true);
      console.log(row);
      ClienteDocumentoService.download(row.idClienteDocumento)
        .then((response) => {
          const linkSource = response;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = row.nomeDocumento;
          downloadLink.click();
        })
        .finally(() => setFazendoDownload(false));
    };

    return (
      <Grid container spacing={2}>
        <Grid container>
          {fazendoDownload ? (
            <Loading />
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<Download />}
              onClick={() => {
                download(rowData);
              }}
              title="Download"
            >
              Download
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    pesquisar(0, idMutuarioParam);
    contrato(idMutuarioParam);
  }, []);

  return (
    <>
      <Helmet title="Contrato de dados bancarios" />
      <Typography variant="h4" gutterBottom display="inline">
        Dados Bancarios
      </Typography>
      <BreadcrumbsNav label="Dados Bancarios" />
      <Divider my={6} />
      {carregandoContrato ? (
        <Loading></Loading>
      ) : (
        <>
          <br></br>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h4">INFORMAÇÕES DO CONTRATO</Typography>
            <br></br>
            <Divider my={6} />
            <br></br>
            <Grid container sx={{ p: 3 }}>
              <Grid container my={2} justifyContent={"space-between"}>
                <Grid display={"flex"} gap={3}>
                  <Grid>
                    <Typography variant="h6">CLIENTE: </Typography>
                  </Grid>
                  <Typography fontWeight={"regular"} variant="h6">
                    {dadosContrato?.cliente?.nome?.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid display={"flex"} gap={3}>
                  <Grid>
                    <Typography variant="h6">APORTE: </Typography>
                  </Grid>
                  <Typography fontWeight={"regular"} variant="h6">
                    {MaskUtil.formataMoeda(dadosContrato?.aporte)}
                  </Typography>
                </Grid>
                <Grid display={"flex"} gap={3}>
                  <Grid>
                    <Typography variant="h6">TAXA DE GESTÃO: </Typography>
                  </Grid>
                  <Typography fontWeight={"regular"} variant="h6">
                    {dadosContrato?.taxaGestao}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container my={2} justifyContent={"space-between"}>
                <Grid display={"flex"} gap={3}>
                  <Grid>
                    <Typography variant="h6">STATUS: </Typography>
                  </Grid>
                  <Typography fontWeight={"regular"} variant="h6">
                    {dadosContrato?.status}
                  </Typography>
                </Grid>
                <Grid display={"flex"} gap={3}>
                  <Grid>
                    <Typography variant="h6">CÓDIGO: </Typography>
                  </Grid>
                  <Typography fontWeight={"regular"} variant="h6">
                    {dadosContrato?.idContratoContea}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <br></br>
          <Paper sx={{ p: 3 }}>
            <Grid container gap={2} alignItems={"center"}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!(dadosContrato?.status == "PROPOSTA")}
                  startIcon={<Send />}
                  onClick={() => {
                    ContratoService.enviarCompliance(idMutuarioParam).then(
                      () => {
                        contrato(idMutuarioParam);
                      }
                    );
                  }}
                >
                  Enviar Compliance
                </Button>
              <Button
                disabled={!(dadosContrato?.status == "ACEITO")}
                variant="contained"
                color="info"
                onClick={() => {
                  setGerandoDocumento(true);
                  gerarDocumento();
                }}
                startIcon={gerandoDocumento ? <CircularProgress color="background" size={20}></CircularProgress> : <CgPrinter />}
                >
                Gerar Documento
              </Button>
              {PermissionUtil.hasPermission("aprovar_contea", user) && (
                <>
                  <Button
                    disabled={!(dadosContrato?.status == "COMPLIANCE")}
                    variant="contained"
                    onClick={() => {
                      setGerandoPrevia(true);
                      gerarDocumento();
                    }}
                    color="warning"
                    startIcon={gerandoPrevia ? <CircularProgress color="background" size={20}></CircularProgress> : <CgPrinter />}
                  >
                    Gerar Previa
                  </Button>
                  <Button
                    disabled={!(dadosContrato?.status == "COMPLIANCE")}
                    variant="contained"
                    onClick={() => aceitarContrato(idMutuarioParam)}
                    color="primary"
                    startIcon={<Verified />}
                  >
                    Aceitar Contrato
                  </Button>
                  <Button
                    disabled={!(dadosContrato?.status == "COMPLIANCE")}
                    onClick={() => recusarContrato(idMutuarioParam)}
                    variant="contained"
                    color="error"
                    startIcon={<Close />}
                  >
                    Recusar contrato
                  </Button>
                </>
              )}
            </Grid>
          </Paper>
          <br></br>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h4">DOCUMENTOS DO CLIENTE</Typography>
            <br></br>
            {carregandoDocumento ? (
              <Loading></Loading>
            ) : (
              <Grid container spacing={2} justifyContent={"stretch"}>
                {listaDocumentos?.content?.map((element) => {
                  return (
                    <Grid item md={3}>
                      <Card sx={{ height: "100%" }} variant="outlined">
                        <CardContent sx={{ height: "100%" }}>
                          <Grid
                            sx={{ height: "100%" }}
                            display="flex"
                            flexDirection="column"
                            justifyContent={"space-between"}
                          >
                            <Grid item md={12}>
                              <Typography fontWeight={"bold"} component="div">
                                <Grid
                                  display="flex"
                                  gap={3}
                                  justifyContent="space-between"
                                >
                                  <Grid md={3}>TIPO:</Grid>
                                  <Grid>{element.tipoArquivoFormatado}</Grid>
                                </Grid>
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Typography fontWeight={"bold"} component="div">
                                <Grid
                                  display="flex"
                                  gap={3}
                                  justifyContent="space-between"
                                >
                                  <Grid md={3}>NOME:</Grid>
                                  <Grid>
                                    {element?.nomeDocumento?.toUpperCase()}
                                  </Grid>
                                </Grid>
                              </Typography>
                            </Grid>
                            <br></br>
                            <Divider></Divider>
                            <br></br>
                            <ButtonDownload rowData={element}></ButtonDownload>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Paper>
          <br></br>
          {dadosContrato?.status == "PROPOSTA" ? (
            <>
              <Paper sx={{ p: 4 }}>
                <Typography variant="h4">DADOS BANCARIOS</Typography>
                <br></br>
                <Divider my={6} />
                <br></br>
                <CadastroDadosBancarios
                  idDadosBancarios={dadosBancariosId}
                  idContratoContea={idMutuarioParam}
                  setIdDadosBancarios={setDadosBancariosId}
                  saveHandler={() => {
                    pesquisar(0, idMutuarioParam);
                  }}
                ></CadastroDadosBancarios>
              </Paper>
              <br></br>
              <Paper>
                <RenderTable></RenderTable>
              </Paper>
            </>
          ) : (
            <Grid container gap={3}>
              {objPage?.content?.map((element) => (
                <Grid container>
                  <Paper style={{ width: "100%" }} sx={{ p: 4 }}>
                    <Grid container sx={{ p: 4 }}>
                      <Grid
                        md={12}
                        my={3}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                      >
                        <Grid gap={2} display={"flex"}>
                          <Typography variant="h5">BANCO:</Typography>
                          <Typography variant="h5" fontWeight="regular">
                            {element.banco.nome}
                          </Typography>
                        </Grid>
                        <Grid gap={2} display={"flex"}>
                          <Typography variant="h5">VALOR EM CONTA:</Typography>
                          <Typography variant="h5" fontWeight="regular">
                            {MaskUtil.moneySeparadorMilhar(element.valorConta)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        md={12}
                        display={"flex"}
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                      >
                        <Grid
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                          flexDirection={"row"}
                        >
                          <Typography variant="h6">CONTA:</Typography>
                          <Typography variant="h6" fontWeight="regular">
                            {[element.conta, element.contaDigito].join("-")}
                          </Typography>
                        </Grid>
                        <Grid
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                          flexDirection={"row"}
                        >
                          <Typography variant="h6">AGÊNCIA:</Typography>
                          <Typography variant="h6" fontWeight="regular">
                            {[element.agencia, element.agenciaDigito].join("-")}
                          </Typography>
                        </Grid>
                        <Grid
                          display={"flex"}
                          alignItems={"center"}
                          gap={2}
                          flexDirection={"row"}
                        >
                          <Typography variant="h6">TIPO:</Typography>
                          <Chip label={element.tipoConta} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default DadosBancarios;
